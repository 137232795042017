<!--
 * @Author: hwu
 * @Date: 2020-11-26 19:01:36
 * @Description: 订单详情页 -> pos机支付 -> 选择支付方式弹窗
 * @LastEditTime: 2024-12-02 16:55:18
-->
<template>
  <div class="wh-popup-wraper" @touchmove.stop>
    <transition name="wh-popup">
      <div class="wh-popup-box" :style="{ height: '90%' }" v-if="show">
        <div class="payment-box">
          <div class="payment-header">
            <span class="header-title">请选择收款方式</span>
          </div>
          <div class="payment-body">
            <div class="payment-way" v-for="(item, index) in payWayList" :key="index" @click="selectPayWay(item)">
              <div class="payment-way_logo">
                <svg slot="icon" class="icon" aria-hidden="true">
                  <use :xlink:href="transformPayWayIcon(item.code)" />
                </svg>
              </div>
              <div class="payment-way_info">
                <div class="payment-way_info-name">
                  <span> {{ item.name }}</span>
                  <span class="payment-way_info-tag" v-show="item.code === 0">【首选】</span>
                </div>
                <div class="payment-way_info-desc" v-show="item.code === 3 || item.code === 4">扫顾客的支付码收款</div>
                <div class="payment-way_info-desc" v-show="item.code === 0">顾客用微信扫本设备显示的二维码完成支付</div>
                <div class="payment-way_info-desc color-warning" v-show="item.code === 0">此支付方式可将顾客转化为注册会员</div>
              </div>
              <div class="payment-way_select">
                <i class="iconfont icondanxuan-xuanzhong active" v-if="item.code === selectedWay" />
                <i class="iconfont icondanxuan-bukexuan disabled" v-else-if="item.disabled"></i>
                <i class="iconfont icondanxuan-weixuanzhong" v-else />
              </div>
            </div>
            <!-- 物业支付活动 -->
            <div class="payment-way with-desc" v-for="(promo, index) in promoList" :key="`${promo.prmoId}${index}`">
              <div class="payment-way_logo">
                <svg slot="icon" class="icon" aria-hidden="true">
                  <!-- 积分 -->
                  <use xlink:href="#iconjifenduihuan" v-if="promo.promoSet === 25" />
                  <!-- 储值卡 -->
                  <use xlink:href="#iconhuiyuanka1" v-else />
                </svg>
              </div>
              <div class="payment-way_info">
                <div class="payment-way_info-name">{{ promo.promoName }}</div>
              </div>
              <div class="payment-way_amount" v-show="promo.selected" @click="showPromoDialog(promo)">-￥{{ promo.amount }}</div>
              <div class="payment-way_select" @click="handlePromo(promo)">
                <i class="iconfont iconduoxuan-xuanzhong active" v-if="promo.selected" />
                <i class="iconfont iconduoxuan-bukexuan disabled" v-else-if="promo.disable === 1"></i>
                <i class="iconfont iconduoxuan-weixuanzhong" v-else />
              </div>
              <div class="payment-way_desc">
                <template v-if="promo.excludeItemIds && promo.excludeItemIds.length">
                  <span class="color-primary" @click="showItemPopup(promo.excludeItemIds)">{{ promo.excludeItemIds.length }}款指定商品</span>
                  <span>不参与抵扣，</span>
                </template>
                <span v-if="promo.promoSet === 25">每{{ promo.integralRedeemNum }}个积分兑换{{ promo.integralRedeemAmount }}元，</span>
                <span v-if="promo.maxPayAmount > 0 || !promo.exAmount">最多可抵扣{{ promo.maxPayAmount }}元</span>
                <span v-if="promo.maxPayAmount > 0 && promo.exAmount">，</span>
                <span class="color-danger" v-if="promo.exAmount">溢收{{ promo.exAmount }}元</span>
              </div>
            </div>

            <!-- 商品弹窗组件 -->
            <item-select-popup :show.sync="itemPopupShow" :brandCode="order.brandCode" :selectedIds="selectedItemIds" :itemLevel="1" :editable="false" height="90%" />

            <dialog-box :title="`${selectedPromo.promoName}抵扣`" class="form-dialog" :show="show && promoDialogShow" :showLeftBtn="true" leftBtnText="取消" rightBtnText="确定" @onLeftClick="promoDialogShow = false" @onRightClick="submitPromo">
              <div slot="body">
                <div class="promo-warning">请先在第三方平台完成核销后，再录入{{ selectedPromo.promoSet === 25 ? '抵扣数量' : '抵扣金额' }}</div>
                <div class="promo-form">
                  <div class="promo-form-item" v-if="selectedPromo.promoSet === 25">
                    <div class="promo-form-item_label">可抵扣积分数量*</div>
                    <div class="promo-form-item_content">
                      <w-input class="promo-form-item_content-input" type="number" v-model="selectedPromo.integralNum" @input="integralNumInput($event)" placeholder="如：100" />
                      <span class="promo-form-item_content-text">积分</span>
                    </div>
                    <div class="promo-form-item_desc">每{{ selectedPromo.integralRedeemNum }}个积分兑换{{ selectedPromo.integralRedeemAmount }}元</div>
                  </div>
                  <div class="promo-form-item" v-else>
                    <div class="promo-form-item_label">可抵扣金额*</div>
                    <div class="promo-form-item_content">
                      <w-input class="promo-form-item_content-input" type="number" v-model="selectedPromo.amount" @input="amountInput($event)" placeholder="如：100" />
                      <span class="promo-form-item_content-text">元</span>
                    </div>
                  </div>
                </div>
              </div>
            </dialog-box>
          </div>
          <btn-footer>
            <template slot="body">
              <div class="btn-box">
                <button class="btn-item right-btn" @click="confirmPayWay">
                  <template v-if="order.actualAmount > 0">
                    <span>确认收款￥</span>
                    <span class="order-amount">{{ order.actualAmount }}</span>
                  </template>
                  <template v-else>
                    <span>确认下单</span>
                    <span v-if="payPromoExAmount > 0">（溢收￥{{ payPromoExAmount }}）</span>
                  </template>
                </button>
              </div>
            </template>
          </btn-footer>
        </div>
      </div>
    </transition>
    <transition name="wh-backdrop">
      <div class="wh-popup-backdrop" @click="onBackdropClick" v-if="show"></div>
    </transition>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import BtnFooter from '@/components/common/BtnFooter'
import DialogBox from '@/components/common/DialogBox'
import ItemSelectPopup from '@/components/business/ItemSelectPopup'
import { listStorePayWaysApi } from '@/api/store'
import { listPropertyPromoApi, addPropertyPromosApi } from '@/api/order'
export default {
  name: 'payment-pos-way-popup',
  components: { BtnFooter, DialogBox, ItemSelectPopup },
  props: {
    show: { type: Boolean, required: true, default: false },
    order: { type: Object, required: true, default: () => {} }
  },
  data() {
    return {
      selectedWay: 0,
      allPayWayList: [
        {
          code: 0,
          name: '微信扫码',
          logo: '#iconweixinsaomazhifu',
          disabled: false
        },
        {
          code: 1,
          name: '现金',
          logo: '#iconxianjinzhifu1',
          disabled: false
        },
        {
          code: 2,
          name: '刷卡',
          logo: '#iconshuakazhifu',
          disabled: false
        },
        {
          code: 3,
          name: '微信',
          logo: '#iconweixinzhifu',
          disabled: false
        },
        {
          code: 4,
          name: '支付宝',
          logo: '#iconzhifubaozhifu1',
          disabled: false
        }
      ],
      payWayList: [],
      promoList: [],
      selectedPromo: {},
      promoDialogShow: false,
      itemPopupShow: false,
      selectedItemIds: [],
      btnText: ''
    }
  },
  computed: {
    ...mapState('order', ['storeId', 'userId', 'payType', 'selectedPayPromoList']),
    customScanPayDisabled() {
      // 通过【订单-收款】拉到员工端的订单或通过中断支付的待办进入，禁止客扫
      if (this.payType === '1' || this.payType === '2') {
        return true
      }
      // 选择了店长折扣，禁止客扫
      if (this.order?.storeHeadDiscount && this.order?.storeHeadDiscount?.selected === 1) {
        return true
      }
      // 支付类活动选择了，禁止客扫
      if (this.promoList.some((x) => x.selected)) {
        return true
      }
      // 扫了顾客会员码的，禁止客扫
      if (this.order.scanMemberId) {
        return true
      }
      return false
    },
    payPromoExAmount() {
      if (!this.promoList?.length) {
        return 0
      }
      return this.promoList
        .filter((x) => x.exAmount && x.exAmount > 0)
        .reduce((result, item) => {
          return (result += item.exAmount)
        }, 0)
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.listStorePayWays()
        this.listPropertyPromos()
        if (this.selectedPayPromoList && this.selectedPayPromoList.length) {
          this.refreshPromo(4, this.selectedPayPromoList)
          this.updateSelectedPayPromoListVuex(null)
        }
      }
    },
    customScanPayDisabled(val) {
      this.payWayList.forEach((item) => {
        // 微信客扫
        if (item.code === 0) {
          item.disabled = val
          // 如果是禁用，并且当前选中了客扫，就重置选择
          if (val && this.selectedWay === 0) {
            this.selectedWay = 3
          }
        }
      })
    }
  },
  methods: {
    ...mapActions('order', ['updateSelectedPayPromoListVuex']),
    listStorePayWays() {
      listStorePayWaysApi(this.storeId).then((res) => {
        if (res.status !== 0 || !res.data || res.data.length === 0) {
          this.$toast('门店支付方式获取失败')
          return false
        }
        const payWayList = res.data
        payWayList.forEach((i) => {
          i.code = parseInt(i.code)
        })
        // 如果该门店支持微信支付，则手动添加客扫支付选项
        if (payWayList.some((item) => item.code === 3)) {
          const customScanPay = {
            code: 0,
            name: '微信扫码',
            disabled: this.customScanPayDisabled
          }
          payWayList.push(customScanPay)
          if (!this.selectedWay) {
            if (this.customScanPayDisabled) {
              this.selectedWay = 3
            } else {
              this.selectedWay = 0
            }
          }
        }
        this.payWayList = this.sortPayWay(payWayList)
      })
    },
    listPropertyPromos() {
      listPropertyPromoApi(this.storeId, this.order.orderId, this.userId, 1).then((res) => {
        if (res.status !== 0 || !res.data) {
          return false
        }
        this.promoList = res.data || []
        this.promoList.forEach((item) => {
          if (!item.integralNum) {
            item.integralNum = ''
          }
          if (item.maxPayAmount <= 0) {
            item.disable = 1
          }
        })
      })
    },
    sortPayWay(payWayList) {
      let returnList = []
      if (payWayList.find((item) => item.code === 0)) {
        returnList.push(payWayList.find((item) => item.code === 0))
      }
      if (payWayList.find((item) => item.code === 3)) {
        returnList.push(payWayList.find((item) => item.code === 3))
      }
      if (payWayList.find((item) => item.code === 4)) {
        returnList.push(payWayList.find((item) => item.code === 4))
      }
      if (payWayList.find((item) => item.code === 1)) {
        returnList.push(payWayList.find((item) => item.code === 1))
      }
      if (payWayList.find((item) => item.code === 2)) {
        returnList.push(payWayList.find((item) => item.code === 2))
      }
      // 今日订单的二维码收款，不允许使用客扫支付，直接隐藏
      return returnList
    },
    transformPayWayIcon(code) {
      const payWay = this.allPayWayList.find((x) => x.code === code)
      if (!payWay) {
        return ''
      }
      return payWay.logo
    },
    selectPayWay(val) {
      if (val.disabled) {
        return false
      }
      this.selectedWay = parseInt(val.code)
      this.$emit('selectedWay', this.selectedWay)
    },
    confirmPayWay() {
      // 0元不可以不用选支付方式
      if (this.order.actualAmount <= 0) {
        this.$emit('onSubmit', this.selectedWay)
        return false
      }
      if (!this.selectedWay && this.selectedWay !== 0) {
        this.$toast('请选择支付方式')
        return false
      }
      this.$emit('onSubmit', this.selectedWay)
    },
    showItemPopup(itemIdList) {
      this.selectedItemIds = itemIdList
      this.itemPopupShow = true
    },
    handlePromo(promo) {
      if (promo.disable === 1) {
        return
      }
      this.selectedPromo = promo
      if (promo.selected) {
        this.refreshPromo(2)
      } else {
        this.showPromoDialog(promo)
      }
    },
    showPromoDialog(promo) {
      this.selectedPromo = promo
      this.promoDialogShow = true
    },
    /**
     * 保留小数点后两位
     */
    amountInput(event) {
      let val = event.match(/^\d*(\.?\d{0,2})/g)[0]
      this.$nextTick(() => {
        this.$set(this.selectedPromo, 'amount', val)
      })
    },
    /**
     * 保留小数点后两位
     */
    integralNumInput(event) {
      let val = event.match(/^\d*/g)[0]
      this.$nextTick(() => {
        this.$set(this.selectedPromo, 'integralNum', val)
      })
    },
    submitPromo() {
      let amount = 0
      if (this.selectedPromo.promoSet === 25) {
        if (!this.selectedPromo.integralNum) {
          this.$toast('请填写抵扣积分数量')
          return false
        }
        amount = (parseInt(this.selectedPromo.integralNum) * this.selectedPromo.integralRedeemAmount) / this.selectedPromo.integralRedeemNum
      } else {
        if (!this.selectedPromo.amount) {
          this.$toast('请填写抵扣金额')
          return false
        }
        amount = this.selectedPromo.amount
      }
      if (amount > this.selectedPromo.maxPayAmount) {
        this.$toast(`最多可抵扣${this.selectedPromo.maxPayAmount}元`)
        return false
      }
      this.refreshPromo(1)
    },
    /**
     * 提交接口，刷新活动
     * type: 1-选中，2-取消勾选， 3-清空, 4-选中指定列表
     */
    refreshPromo(type, defaultList) {
      // 这里取所有已勾选的活动（除当前操作的活动外）
      let selectedList = this.promoList.filter((x) => x.selected && x.promoId !== this.selectedPromo.promoId)
      // 如果当前活动是选中，就将其加入要提交的表单，如果是取消勾选，就不用（接口会将订单里面所有不在本批次的物业活动剔除掉）
      if (type === 1) {
        selectedList.push(this.selectedPromo)
      }
      if (type === 3) {
        selectedList = []
      }
      if (type === 4) {
        selectedList = defaultList
      }
      return new Promise((resolve, reject) => {
        addPropertyPromosApi(this.order.orderId, this.userId, this.userId, selectedList, 1).then((res) => {
          if (res.status !== 0) {
            this.$toast('保存失败')
            resolve()
            return false
          }
          this.$emit('onPromoChange', res.data)
          this.listPropertyPromos()
          this.promoDialogShow = false
          resolve()
        })
      })
    },
    onBackdropClick() {
      const selectedPromoList = this.promoList.filter((x) => x.selected)
      this.updateSelectedPayPromoListVuex(selectedPromoList)
      this.refreshPromo(3).then(() => {
        this.$emit('onClose')
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.wh-popup-box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 750px;
  height: 100%;
  background-color: $color-white;
  border-radius: 32px 32px 0 0;
  z-index: 2001;
}
.wh-popup-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
  z-index: 2000;
}
.wh-popup-enter-active,
.wh-popup-leave-active {
  transition: transform 0.3s;
}
.wh-popup-enter,
.wh-popup-leave-to {
  transform: translateY(100%);
}
.wh-backdrop-enter-active,
.wh-backdrop-leave-active {
  transition: opacity 0.3s;
}
.wh-backdrop-enter,
.wh-backdrop-leave-to {
  opacity: 0;
}

.payment-box {
  position: relative;
  width: 100%;
  height: 100%;
  .payment-header {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    padding: 22px 24px;
    align-items: center;
    flex-wrap: nowrap;
    background-color: $color-white;
    border-radius: 32px 32px 0 0;
    z-index: 11;
    .header-title {
      flex: 1;
      line-height: 36px;
      font-size: 30px;
      font-weight: bold;
      color: $color-text-main;
      white-space: nowrap;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 24px;
      right: 24px;
      border-top: 1px solid $color-border;
    }
  }
  .payment-body {
    width: 100%;
    height: 100%;
    padding: 80px 0 100px 0;
    overflow-y: auto;
    // -webkit-overflow-scrolling: touch;
  }
}

.payment-way {
  position: relative;
  display: flex;
  width: 100%;
  padding: 24px;
  align-items: flex-start;
  &.with-desc {
    flex-wrap: wrap;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 24px;
    right: 24px;
    border-top: 1px solid $color-border;
  }
  //&.disabled {
  //  opacity: 0.6;
  //}
  .payment-way_logo {
    flex: 0;
    font-size: 0;
    svg {
      width: 40px;
      height: 40px;
    }
  }
  .payment-way_info {
    flex: 1;
    margin-left: 12px;
    line-height: 36px;
    .payment-way_info-name {
      line-height: 40px;
      font-size: 30px;
      color: $color-text-main;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    .payment-way_info-tag {
      font-size: 24px;
      color: $color-warning;
    }
    .payment-way_info-desc {
      color: $color-text-sub;
      font-size: 24px;
      padding: 0;
      margin: 0;
    }
  }
  .payment-way_amount {
    flex: 0;
    margin-right: 12px;
    line-height: 40px;
    font-size: 26px;
    color: $color-primary;
    white-space: nowrap;
  }
  .payment-way_select {
    flex: 0;
    color: $color-text-disabled;
    .iconfont {
      font-size: 40px;
      vertical-align: bottom;

      &.active {
        color: $color-primary;
        opacity: 1;
      }
      &.disabled {
        color: $color-text-disabled !important;
        opacity: 0.5 !important;
      }
    }
  }
  .payment-way_desc {
    flex: 0 0 100%;
    margin-top: 8px;
    color: $color-text-sub;
    font-size: 24px;
  }
}

.page-footer {
  .btn-item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
  }
  .order-amount {
    font-size: 48px;
    font-weight: bold;
  }
}

.promo-warning {
  width: 100%;
  margin-bottom: 24px;
  line-height: 32px;
  text-align: left;
  font-size: 24px;
  color: $color-warning;
}
.promo-form {
  width: 100%;
  text-align: left;
  .promo-form-item {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    .promo-form-item_label {
      flex: auto;
      text-align: left;
    }
    .promo-form-item_content {
      flex: auto;
      text-align: right;
      .promo-form-item_content-input {
        width: 132px;
        height: 48px;
        padding: 0 16px;
        margin-left: 24px;
        font-size: 24px;
        border: 1px solid $color-text-placeholder;
        border-radius: 8px;
      }
      .promo-form-item_content-text {
        margin-left: 8px;
        white-space: nowrap;
      }
    }
    .promo-form-item_desc {
      flex: 0 0 100%;
      margin-top: 8px;
      line-height: 36px;
      font-size: 24px;
      color: $color-text-sub;
    }
  }
}
</style>
