<!--
 * @Author: hwu
 * @Date: 2020-11-26 23:04:53
 * @Description: 订单详情页 -> pos机支付 -> 选择支付方式弹窗
 * @LastEditTime: 2024-12-02 18:38:56
-->
<template>
  <div class="dialog-wrapper">
    <!-- 服务员登录弹窗 -->
    <emp-login-dialog :show="show && loginDialogShow" title="请叫服务员来，为您操作买单" @onClose="closeParentDialog" @onSuccess="empLoginSuccess" />
    <!-- 选择支付方式弹窗 -->
    <payment-pos-way-popup :show="show && payWayPopupShow" :order="order" @onClose="closeParentDialog" @onSubmit="confirmPayWay" @selectedWay="selectedWay" @onPromoChange="onPromoChange" />
    <!-- 现金收款弹窗 -->
    <dialog-box title="现金收款" :show="show && cashDialogShow" :showLeftBtn="true" leftBtnText="返回" rightBtnText="完成" @onLeftClick="closeCashDialog" @onRightClick="submitCash">
      <div slot="body">
        <div class="form-box">
          <div class="form-item">
            <div class="form-item_label">应收金额</div>
            <div class="form-item_content color-danger">￥{{ needPayAmount }}</div>
          </div>
          <div class="input-row">
            <div class="input-row-left">收到现金*</div>
            <div class="input-row-right">
              <w-input class="input-row-right-input" type="number" placeholder="请输入金额" v-model="payParamVo.amount" @input="amountInput($event, payParamVo, 'amount')" />
              <span class="input-row-right-text">元</span>
            </div>
          </div>
          <div class="form-item">
            <div class="form-item_label">找零金额</div>
            <div class="form-item_content color-danger">{{ cashChangeAmount }}</div>
          </div>
          <div class="form-item" v-if="needInputMobile">
            <div class="form-item_label">顾客手机号</div>
            <div class="form-item_content">
              <div class="content-row">
                <form action="" @submit.prevent="$closeKeyboard()">
                  <input type="tel" v-model="customMobile" class="row_input" placeholder="请输入手机号" />
                </form>
              </div>
            </div>
          </div>
          <div class="form-item no-border" v-if="needInputMobile">
            <div class="form-item_desc">*手机号用于给顾客发送取餐提醒</div>
          </div>
        </div>
      </div>
    </dialog-box>
    <!-- 刷卡收款弹窗 -->
    <dialog-box title="刷卡收款" :show="show && cardDialogShow" :showLeftBtn="true" leftBtnText="返回" rightBtnText="完成" @onLeftClick="closeCardDialog" @onRightClick="submitCard">
      <div slot="body">
        <div class="form-box">
          <div class="form-item">
            <div class="form-item_label">应收金额</div>
            <div class="form-item_content color-danger">￥{{ needPayAmount }}</div>
          </div>
          <div class="input-row">
            <div class="input-row-left">实刷金额*</div>
            <div class="input-row-right">
              <w-input class="input-row-right-input" type="number" placeholder="请输入金额" v-model="payParamVo.amount" @input="amountInput($event, payParamVo, 'amount')" />
              <span class="input-row-right-text">元</span>
            </div>
          </div>
          <!-- <div class="form-item">
            <div class="form-item_label">上传签单小票*</div>
            <div class="form-item_content">
              <i class="iconfont iconshangchuantupian color-primary"></i>
            </div>
          </div> -->
          <div class="form-item" v-if="needInputMobile">
            <div class="form-item_label">顾客手机号</div>
            <div class="form-item_content">
              <div class="content-row">
                <form action="" @submit.prevent="$closeKeyboard()">
                  <input type="tel" v-model="customMobile" class="row_input" placeholder="请输入手机号" />
                </form>
              </div>
            </div>
          </div>
          <div class="form-item no-border" v-if="needInputMobile">
            <div class="form-item_desc">*手机号用于给顾客发送取餐提醒</div>
          </div>
        </div>
      </div>
    </dialog-box>
    <!-- 微信扫码付款弹窗 -->
    <dialog-box class="custom-scan-pay-box" :show="show && customScanPayDialogShow" title="微信扫码付款" rightBtnText="返回" @onRightClick="closeCashDialog">
      <div slot="body">
        <vue-qr class="qrCode" :text="qrCodeUrl" :margin="0" colorDark="#000" colorLight="#fff" :logoScale="0.3" :size="200"></vue-qr>
        <p class="qrCode-amount">订单金额：￥{{ needPayAmount }}</p>
      </div>
    </dialog-box>
    <!-- 微信扫码付款弹窗 -->
    <!-- <dialog-box :show="show && paySuccessDialogShow" title="收款成功" rightBtnText="知道了" @onRightClick="closePaySuccessDialog">
      <div slot="body">
        <p class="">请将商品和账单一起交付给顾客！</p>
      </div>
    </dialog-box> -->

    <!-- 获取顾客手机号，用于短信提醒 -->
    <dialog-box class="mobile-dialog" title="请输入顾客手机号" :show="needMobileDialogShow" :showLeftBtn="true" leftBtnText="关闭" rightBtnText="完成" @onLeftClick="closeNeedMobileDialog" @onRightClick="confirmSetCustomMobile">
      <div slot="body">
        <div class="form-box">
          <div class="form-item">
            <div class="form-item_label">手机号</div>
            <div class="form-item_content">
              <div class="content-row">
                <form action="" @submit.prevent="$closeKeyboard()">
                  <input type="tel" v-model="customMobile" class="row_input" placeholder="请输入手机号" />
                </form>
              </div>
            </div>
          </div>
          <div class="form-item no-border">
            <div class="form-item_desc">*手机号用于给顾客发送取餐提醒</div>
          </div>
        </div>
      </div>
    </dialog-box>
    <!-- 付款二次提醒弹框 -->
    <!--    服务员下单不要弹窗引导客扫支付弹窗2024.7.15-->
    <dialog-box class="confirm-pay-box" :show="show && confirmPayDialogShow" title="温馨提示" showLeftBtn leftBtnText="知道了" rightBtnText="微信客扫支付" @onBackdropClick="closeCashDialog" @onLeftClick="confirmPay" @onRightClick="changeToCustomScanPay">
      <div slot="body">
        <div class="tip-box">
          <div class="tip-row" v-for="(item, index) in tipsArr" :key="index">
            <div class="row-index">{{ index + 1 }}</div>
            <div class="row-text">{{ item }}</div>
          </div>
        </div>
      </div>
    </dialog-box>
    <!--    外带的取餐码-->
    <dialog-box class="confirm-pay-box" :show="show && takeOutDialogShow" :showTitle="false" rightBtnText="知道了" @onBackdropClick="handlePaySuccess" @onRightClick="handlePaySuccess">
      <div slot="body">
        <div class="dialog-title">
          <span>取餐码</span>
          <span class="color-warning">#</span>
          <span>{{ takeOutDialogTitle }}</span>
        </div>
        <div class="margin-bottom-16">请把外带账单给顾客！</div>
        <div class="color-warning small-text">可在【我的>订单】查看此单；</div>
      </div>
    </dialog-box>
  </div>
</template>
<script>
import vueQr from 'vue-qr'
import { mapState } from 'vuex'
import validate from '@/utils/validate'
import DialogBox from '@/components/common/DialogBox'
import EmpLoginDialog from '@/components/business/EmpLoginDialog'
import PaymentPosWayPopup from './PaymentPosWayPopup.vue'
import { payByOfflineApi, payByWxBarCodeApi, payByAlipayBarCodeApi, payByZeroApi } from '@/api/pay'
import { getOrderForPrintBillApi, setCustomMobileApi } from '@/api/order'
import { scanQrCodeApi, createScanDeviceConnectionApi, printBillApi, broadenWebViewApi } from '@/api/apk'
import { generateWxProgramLinkApi } from '@/api/wechat'
export default {
  name: 'payment-pos-dialog',
  components: { DialogBox, EmpLoginDialog, PaymentPosWayPopup, vueQr },
  props: {
    show: { type: Boolean, required: true, default: false },
    order: { type: Object, required: true, default: () => {} },
    needInputMobile: { type: Boolean, required: true, default: false }
  },
  data() {
    return {
      loginDialogShow: false,
      payWayPopupShow: false,
      cashDialogShow: false,
      cardDialogShow: false,
      customScanPayDialogShow: false,
      needPayAmount: '',
      payCode: '',
      payParamVo: {
        orderId: this.orderId,
        userId: '',
        payCode: '',
        amount: '',
        ticketImg: '',
        waiterId: ''
      },
      barCode: '',
      needMobileDialogShow: false,
      customMobile: '',
      submitDisabled: false,
      qrCodeUrl: '',
      paySuccessDialogShow: false,
      confirmPayDialogShow: false,
      tipsArr: ['此“收款方式”，未参与点单的“买单人”无法自助开具电子发票。', '此“收款方式”，“买单人”无法核销其手机绑定的会员卡和优惠券；', '此“收款方式”，无法获取顾客的注册信息。'],
      takeOutDialogShow: false,
      takeOutDialogTitle: ''
    }
  },
  computed: {
    ...mapState('order', ['storeId', 'userId', 'entranceType', 'existsWeighing', 'cartId', 'tableId', 'afterPayBackUrl', 'isPad', 'payType']),
    cashChangeAmount() {
      if (!this.needPayAmount || !this.payParamVo.amount) {
        return ''
      }
      const changeAmout = this.payParamVo.amount - this.needPayAmount
      if (changeAmout > 0) {
        return '￥' + parseFloat(changeAmout.toFixed(2))
      } else {
        return '￥0'
      }
    },
    orderId() {
      return this.order?.orderId || ''
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.initDialog()
        if (this.payType !== '1' && this.payType !== '2') {
          this.generateCustomPayQrCodeUrl()
        }
        // 开启扫码枪连接
        this.createScanConnection()
      } else {
        // 关闭扫码枪连接
        this.closeScanConnection()
      }
    }
  },
  methods: {
    generateCustomPayQrCodeUrl() {
      const programQuery = `orderId=${this.orderId}&cartId=${this.cartId}&storeId=${this.storeId}&tableId=${this.tableId}&fromCustomScanPay=1`
      const programPath = this.order.payModel === 2 ? 'packages/order/pages/PaymentAfterPay' : 'packages/order/pages/Payment'
      const param = {
        brandCode: this.order.brandCode,
        isExpire: 0,
        expireType: 1,
        expireInterval: 1,
        path: programPath,
        query: programQuery
      }
      generateWxProgramLinkApi(param).then((res) => {
        if (res.status !== 0) {
          this.$toast('生成小程序链接失败')
          this.qrCodeUrl = ''
          return false
        }
        this.qrCodeUrl = res.data
      })
    },
    initDialog() {
      // 如果是pos机点餐，并且有称重商品，则无需弹出登录窗口
      if (this.existsWeighing && this.userId) {
        this.empLoginSuccess(this.userId)
      } else {
        this.closeAllDialog()
        this.loginDialogShow = true
      }
    },
    createScanConnection() {
      // 开启扫码枪连接
      createScanDeviceConnectionApi('3', '')
      // 监听扫码回调通知
      window.scanCallBack = this.scanCallBack
      // pos机点餐，iframe模式监听扫码回调通知
      window.addEventListener('message', this.scanPayCallBack, false)
    },
    closeScanConnection() {
      // 关闭扫码枪连接
      createScanDeviceConnectionApi('0', '')
      // 关闭扫码回调通知
      window.scanCallBack = null
      window.removeEventListener('message', this.scanPayCallBack, false)
    },
    // 登录弹窗代码块
    empLoginSuccess(val) {
      this.payParamVo.waiterId = val
      this.payParamVo.userId = this.userId
      this.payParamVo.orderId = this.orderId
      this.closeAllDialog()
      this.payWayPopupShow = true
    },
    selectedWay(val) {
      this.payCode = val
    },
    closeNeedMobileDialog() {
      this.needMobileDialogShow = false
      this.startPay()
    },
    confirmSetCustomMobile() {
      if (!validate.mobile(this.customMobile)) {
        this.$toast('请输入正确的手机号')
        return false
      }
      setCustomMobileApi(this.orderId, this.userId, this.customMobile).then((res) => {
        if (res.status !== 0) {
          this.$toast('保存失败')
          return false
        }
        this.closeNeedMobileDialog()
      })
    },
    // 支付方式弹窗代码块
    confirmPayWay(val) {
      this.payCode = val
      // 需要录入手机号，并且是微信或支付宝扫码，才会在这里弹窗要求输入手机号（除此之外，客扫不用输入，现金或刷卡在里面输入）
      // 外带不需要输入手机号了
      if (this.needInputMobile && (val === 3 || val === 4)) {
        this.needMobileDialogShow = true
        return false
      }
      // 如果没有待支付金额了，就直接0元下单
      if (this.order.actualAmount <= 0) {
        this.payByZero()
        return false
      }
      this.startPay()
    },
    startPay() {
      switch (this.payCode) {
        case 0:
          this.closeAllDialog()
          this.customScanPayDialogShow = true
          this.needPayAmount = this.order.actualAmount
          break
        case 1:
          // 现金支付，弹出现金弹窗
          this.payParamVo.payCode = this.payCode
          this.needPayAmount = Math.floor(this.order.actualAmount * 10) / 10
          this.closeAllDialog()
          this.cashDialogShow = true
          break
        case 2:
          // 刷卡支付，弹出刷卡弹窗
          this.payParamVo.payCode = this.payCode
          this.needPayAmount = this.order.actualAmount
          this.closeAllDialog()
          this.cardDialogShow = true
          break
        case 3:
          this.payWayPopupShow = false
          // 服务员下单不要弹窗引导客扫支付弹窗2024.7.15
          // this.confirmPayDialogShow = true
          // 微信支付，调起扫一扫
          scanQrCodeApi()
          setTimeout(() => {
            this.closeCashDialog()
          }, 1000)
          break
        case 4:
          this.payWayPopupShow = false
          // 服务员下单不要弹窗引导客扫支付弹窗2024.7.15
          // this.confirmPayDialogShow = true
          // 支付宝支付，调起扫一扫
          scanQrCodeApi()
          setTimeout(() => {
            this.closeCashDialog()
          }, 1000)
          break
      }
    },
    confirmPay() {
      scanQrCodeApi()
      setTimeout(() => {
        this.closeCashDialog()
      }, 1000)
    },
    changeToCustomScanPay() {
      this.payCode = 0
      this.closeAllDialog()
      this.customScanPayDialogShow = true
      this.needPayAmount = this.order.actualAmount
    },
    // 现金弹窗代码块
    closeCashDialog() {
      this.closeAllDialog()
      this.payWayPopupShow = true
    },
    submitCash() {
      if (!this.payParamVo.amount) {
        this.$toast('请填写收取金额')
        return false
      }
      if (this.payParamVo.amount < this.needPayAmount) {
        this.$toast('收取现金不能少于应收金额')
        return false
      }
      if (this.payParamVo.amount > this.needPayAmount + 100) {
        this.$toast('收取金额异常，请核实')
        return false
      }
      if (this.customMobile) {
        if (!validate.mobile(this.customMobile)) {
          this.$toast('请输入正确的手机号')
          return false
        }
        setCustomMobileApi(this.orderId, this.userId, this.customMobile).then((res) => {
          this.payByOffline()
        })
        return false
      }
      this.payByOffline()
    },
    // 刷卡弹窗代码块
    closeCardDialog() {
      this.closeAllDialog()
      this.payWayPopupShow = true
    },
    submitCard() {
      if (!this.payParamVo.amount) {
        this.$toast('请填写刷卡金额')
        return false
      }
      if (this.payParamVo.amount < this.needPayAmount) {
        this.$toast('刷卡现金不能少于应收金额')
        return false
      }
      if (this.customMobile) {
        if (!validate.mobile(this.customMobile)) {
          this.$toast('请输入正确的手机号')
          return false
        }
        setCustomMobileApi(this.orderId, this.userId, this.customMobile).then((res) => {
          this.payByOffline()
        })
        return false
      }
      this.payByOffline()
    },
    payByZero() {
      const paramVo = {
        orderId: this.orderId,
        userId: this.userId
      }
      payByZeroApi(paramVo).then((res) => {
        if (res.status !== 0) {
          this.$toast(res.msg)
          return false
        }
        // 支付成功后的处理
        // 外带取餐码
        if (res.data) {
          this.takeOutDialogTitle = res.data
          this.closeAllDialog()
          this.takeOutDialogShow = true
          return
        }
        this.handlePaySuccess()
      })
    },
    payByOffline() {
      payByOfflineApi(this.payParamVo).then((res) => {
        if (res.status !== 0) {
          this.$toast(res.msg)
          return false
        }
        // 支付成功后的处理
        // 外带取餐码
        if (res.data) {
          this.takeOutDialogTitle = res.data
          this.closeAllDialog()
          this.takeOutDialogShow = true
          return
        }
        this.handlePaySuccess()
      })
    },
    scanPayCallBack(event) {
      if (event.data.state !== 'scanCallBack') {
        return false
      }
      const barCode = event.data.code
      if (!barCode) {
        this.$toast('无效的付款码')
        return false
      }
      this.scanCallBack(barCode)
    },
    scanCallBack(barCode) {
      this.barCode = barCode
      this.confirmPayAfterScanCallBack()
    },
    confirmPayAfterScanCallBack() {
      if (this.payCode === 3) {
        this.payByWxBarCode()
      }
      if (this.payCode === 4) {
        this.payByAlipayBarCode()
      }
    },
    payByWxBarCode() {
      this.$toast.loading({
        duration: 0,
        message: '正在支付',
        forbidClick: true
      })
      const param = {
        orderId: this.orderId,
        userId: this.userId,
        barCode: this.barCode
      }
      payByWxBarCodeApi(param).then((res) => {
        this.$toast.clear()
        if (res.status !== 0) {
          this.$toast(res.msg)
          return false
        }
        // 支付成功后的处理
        // 外带取餐码
        if (res.data) {
          this.takeOutDialogTitle = res.data
          this.closeAllDialog()
          this.takeOutDialogShow = true
          return
        }
        this.handlePaySuccess()
      })
    },
    payByAlipayBarCode() {
      this.$toast.loading({
        duration: 0,
        message: '正在支付',
        forbidClick: true
      })
      const param = {
        orderId: this.orderId,
        userId: this.userId,
        barCode: this.barCode
      }
      payByAlipayBarCodeApi(param).then((res) => {
        this.$toast.clear()
        if (res.status !== 0) {
          this.$toast(res.msg)
          return false
        }
        // 支付成功后的处理
        // 外带取餐码
        if (res.data) {
          this.takeOutDialogTitle = res.data
          this.closeAllDialog()
          this.takeOutDialogShow = true
          return
        }
        this.handlePaySuccess()
      })
    },
    handlePaySuccess() {
      this.$toast(this.order.payModel === 2 ? '买单成功' : '下单成功')
      // 自动打印划菜单
      this.printBill()
      // 关闭弹窗
      this.closeParentDialog()
      // 关闭扫码枪连接
      this.closeScanConnection()
      // 从今日订单和未付款订单待办进入的买单页，买单之后再返回对应的页面
      if (this.afterPayBackUrl) {
        window.location.href = this.afterPayBackUrl
        // 如果是pad点餐，那么就拓宽webview
        if (this.isPad) {
          broadenWebViewApi('phone')
        }
        return
      }
      // 通过待办进入，完成时关闭webview
      if (this.payType === '1' || this.payType === '2') {
        this.$bappSdk.closeWebView()
        return
      }
      this.$router.push({
        name: 'OrderGuide',
        query: {
          storeId: this.storeId,
          userId: this.userId,
          entranceType: this.entranceType,
          isPad: this.isPad ? 1 : 0
        }
      })
    },
    printBill() {
      getOrderForPrintBillApi(this.orderId).then((res) => {
        if (res.status !== 0) {
          return false
        }
        const orderList = res.data
        printBillApi(JSON.stringify(orderList))
      })
    },
    // 总弹窗代码块
    closeAllDialog() {
      this.loginDialogShow = false
      this.payWayPopupShow = false
      this.cashDialogShow = false
      this.cardDialogShow = false
      this.customScanPayDialogShow = false
      this.confirmPayDialogShow = false
      this.takeOutDialogShow = false
    },
    /**
     * 保留小数点后两位
     */
    amountInput(event, payParamVo, name) {
      const val = event.match(/^\d*(\.?\d{0,2})/g)[0]
      this.$nextTick(() => {
        this.$set(payParamVo, name, val)
      })
    },
    onPromoChange(orderDetail) {
      this.$emit('onOrderUpdate', orderDetail)
    },
    closeParentDialog() {
      this.$emit('onClose')
    }
  }
}
</script>
<style lang="scss" scoped>
.form-box {
  width: 100%;
  .form-item {
    display: flex;
    width: 100%;
    padding: 24px 0;
    line-height: 36px;
    align-items: center;
    &:not(:last-child) {
      border-bottom: 1px solid $color-border;
    }
    .form-item_label {
      flex: 1;
      text-align: left;
      font-size: 30px;
      color: $color-text-main;
    }
    .form-item_content {
      flex: 1;
      text-align: right;
      font-size: 30px;
      color: $color-text-normal;
      .content-row {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-end;
        .inline-input {
          width: 200px;
          height: 56px;
          padding: 13px 20px;
          margin: 0 8px;
          font-size: 24px;
          border: 1px solid $color-border;
          border-radius: 8px;
        }
        .row_input {
          flex: 1;
          width: 100%;
          margin: 0 8px;
          font-size: 30px;
          border: none;
          border-radius: 8px;
        }
      }
      .iconfont {
        font-size: 40px;
      }
    }
    .form-item_desc {
      flex: 0 0 100%;
      text-align: left;
      font-size: 26px;
      color: $color-text-sub;
    }
  }
}
.custom-scan-pay-box {
  img {
    width: 280px;
    height: 280px;
    background-color: #fff;
    padding: 8px;
    box-sizing: border-box;
  }
  .qrCode-amount {
    color: #fd7022;
    font-size: 30px;
    font-weight: bold;
  }
}
.mobile-dialog {
  /deep/ .wh-dialog-box {
    z-index: 2101;
  }
  /deep/ .wh-dialog-backdrop {
    z-index: 2100;
  }
}
.tip-box {
  .tip-row {
    display: flex;
    align-items: flex-start;
    .row-index {
      width: 32px;
      height: 32px;
      background: #898787;
      font-size: 24px;
      font-weight: bold;
      color: #ffffff;
      line-height: 32px;
      border-radius: 50%;
      margin: 4px 8px 0 0;
    }
    .row-text {
      flex: 1;
      text-align: left;
    }
  }
}
.input_vant {
  display: flex;
  width: 100%;
  padding: 24px 0 !important;
  line-height: 36px;
  align-items: center;
  justify-content: space-between;
  .input_vant_label {
    font-size: 30px;
    color: $color-text-main;
  }
  /deep/.van-field__value {
    max-width: 200px !important;
    height: 56px !important;
    padding: 13px 20px !important;
    margin: 0 40px 0 8px !important;
    font-size: 24px !important;
    border: 1px solid $color-border !important;
    border-radius: 8px !important;
  }
  .input_vant_extra {
    position: absolute;
    right: 0;
    font-size: 28px;
    color: $color-text-normal;
  }
}
.small-text {
  font-size: 26px;
}
.dialog-title {
  margin-bottom: 24px;
  font-size: 38px;
  color: $color-text-main;
}
.input-row {
  width: 100%;
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .input-row-left {
    font-size: 30px;
    line-height: 36px;
    color: $color-text-main;
  }
  .input-row-right {
    .input-row-right-input {
      width: 200px;
      height: 56px !important;
      padding: 13px 20px !important;
      margin-right: 16px;
      font-size: 24px !important;
      border: 1px solid $color-border !important;
      border-radius: 8px !important;
    }
    .input-row-right-text {
      font-size: 28px;
      color: $color-text-normal;
    }
  }
}
</style>
