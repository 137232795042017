/*
 * @Author: hwu
 * @Date: 2021-01-18 15:31:05
 * @Description: 调用Apk的方法
 * @LastEditTime: 2025-01-02 10:26:37
 */
import { $bappSdk } from '@weihong/bapp-websdk'

/**
 * 检查当前环境是否是店里用app
 * @param {Array} orderList 订单列表
 */
export const checkAppApi = () => {
  if (!window.jsObj) {
    return '0'
  }
  return window.jsObj.checkApp()
}

/**
 * 打印账单
 * @param {Array} orderList 订单列表
 */
export const printBillApi = (orderList) => {
  if ($bappSdk.isBApp()) {
    return $bappSdk.printPaymentReceipt(orderList)
  }
  if (!window.jsObj) {
    return false
  }
  window.jsObj.printPaymentReceipt(orderList)
}

/**
 * 打印划菜单
 * @param {String} orderList 订单列表的字符串
 */
export const printMenuApi = (orderList) => {
  if ($bappSdk.isBApp()) {
    return $bappSdk.printMenuReceipt(orderList)
  }
  if (!window.jsObj) {
    return false
  }
  window.jsObj.printMenuReceipt(orderList)
}

/**
 * 调用pos机的扫一扫
 */
export function scanQrCodeApi() {
  if ($bappSdk.isBApp()) {
    return $bappSdk.scanQrCode()
  }
  if (!window.jsObj) {
    return false
  }
  window.jsObj.scanQrCode()
}

/**
 * 建立扫码枪连接
 * @param {String} scanType 扫码场景，0-关闭连接， 1-桌码，2-商品条码，3-支付码, 4-券码
 * @param {String} cartId 购物车Id，scanType=2的时候需要传
 */
export const createScanDeviceConnectionApi = (scanType, cartId) => {
  if ($bappSdk.isBApp()) {
    return $bappSdk.createScanDeviceConnection(scanType, cartId)
  }
  if (!window.jsObj) {
    return false
  }
  window.jsObj.createScanDeviceConnection(scanType, cartId)
}

/**
 * pad点餐的时候，拓宽webview的宽度
 * @param {String} type webview宽度类型：phone pad
 */
export const broadenWebViewApi = (type) => {
  if ($bappSdk.isBApp()) {
    return $bappSdk.broadenWebView(type)
  }
  if (!window.jsObj) {
    return false
  }
  window.jsObj.broadenWebView(type)
}

/**
 * 发送语音
 * @param {String} audioType 语音类型：app只支持固定语音，GOODS_GET_FAIL-商品获取失败
 * @param {String} text 自定义话术（pos机支持自定义话术）
 */
export const playAudioApi = (audioType, text) => {
  if ($bappSdk.isBApp()) {
    return $bappSdk.playAudio(audioType)
  }
  if (!window.jsObj) {
    return false
  }
  window.jsObj.voiceContent(text)
}
