<!--
 * @Author: hwu
 * @Date: 2024-01-24 13:36:21
 * @Description: 会员卡详情 -- 会员卡详情页面、弹窗的内容区，卡规则描述
 * @LastEditTime: 2024-05-29 15:38:49
-->
<template>
  <div class="card-container">
    <!-- 卡详情顶部区域 -->
    <div class="margin-bottom-16" v-if="withHeader">
      <div class="card-image">
        <div class="card-image-content">
          <w-image fit="cover" :src="transferImage(defaultFaceImage ? defaultFaceImage : cardInfo.promoteImage)" />
          <div class="card-tag">
            <div class="card-tag_item" v-if="cardInfo.nextTime">{{ nextTimeDesc(cardInfo.nextTime) }}</div>
          </div>
        </div>
      </div>
      <div class="card-header">
        <div class="card-header-title">
          <span>{{ cardInfo.brands | brandNameFilter }}{{ cardInfo.cardName }}</span>
          <span class="card-header-title_tag" v-if="cardInfo.barnds && cardInfo.barnds.length > 1">联名卡</span>
        </div>
        <div class="card-header-desc">
          <span>{{ cardDiscountDesc }}</span>
        </div>
      </div>
      <!-- 加一个插槽，购买的时候，自定义充值金额需要在这里插入价格选择区域 -->
      <template v-if="$slots['header-extra']">
        <slot name="header-extra"></slot>
      </template>
    </div>
    <!-- 卡详情内容区 -->
    <div class="card-content">
      <template v-if="!isValueCard">
        <div class="card-rule">
          <div class="card-rule-title">权益&规则</div>
          <div class="card-rule-item">
            <div class="card-rule-item_title">
              <image class="card-rule-item_title-icon" src="/static/images/youxiaoqi.png" mode="widthFix" />
              <span>有效期：</span>
            </div>
            <div class="card-rule-item_detail">
              <div class="detail-row">
                <span class="detail-row-icon">·</span>
                <!-- 福利卡 -->
                <template v-if="isWelfareCard">
                  <span class="detail-row-text">在职期间可用</span>
                </template>
                <!-- 公关卡 -->
                <template v-else-if="isRelationCard">
                  <span class="detail-row-text">有效期{{ cardInfo.validDays }}天</span>
                </template>
                <template v-else>
                  <span class="detail-row-text">有效期</span>
                  <span v-for="(price, priceIndex) in cardInfo.salePrices" :key="priceIndex">
                    <span class="detail-row-text">{{ price.validDays }}天</span>
                    <span class="detail-row-price">{{ price.price | priceDescFilter }}</span>
                    <span class="detail-row-text" v-if="priceIndex < cardInfo.salePrices.length - 1">/</span>
                  </span>
                  <span class="detail-row-text">，{{ effectHoursDesc }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="card-rule" v-for="(brand, index) in cardInfo.brands" :key="index">
          <div class="card-rule-title">【{{ brand.brandName }}】权益&规则</div>
          <div v-for="(right, rightIndex) in brand.discounts" :key="rightIndex">
            <!-- 时段折扣 -->
            <div class="card-rule-item">
              <div class="card-rule-item_title">
                <image class="card-rule-item_title-icon" src="/static/images/quanyi.png" mode="widthFix" />
                <span>权益：</span>
              </div>
              <!-- 菜谱会员价 -->
              <template v-if="right.cardType === '1'">
                <div class="card-rule-item_detail">
                  <div class="detail-row" v-for="(discount, discountIndex) in discountTimes(right.discountDetails)" :key="discountIndex">
                    <span class="detail-row-icon">·</span>
                    <span class="detail-row-text">{{ discountTimeDesc(discount) }}</span>
                    <span class="detail-row-text">可享</span>
                    <span class="detail-row-link" @click="showItemPopup(brand.brandCode, discount.memberPriceItems, null)">指定商品会员价</span>
                  </div>
                  <div class="detail-row" v-if="right.dayLimit || right.weekLimit || right.monthLimit">
                    <span class="detail-row-icon">·</span>
                    <span>{{ right | limitCountFilter }}</span>
                  </div>
                </div>
              </template>
              <!-- 折扣卡 -->
              <template v-if="right.cardType === '2'">
                <div class="card-rule-item_detail">
                  <div class="detail-row" v-for="(discount, discountIndex) in discountTimes(right.discountDetails)" :key="discountIndex">
                    <span class="detail-row-icon">·</span>
                    <span class="detail-row-text">{{ discountTimeDesc(discount) }}</span>
                    <span class="detail-row-text">可享</span>
                    <span class="detail-row-link" v-if="(discount.includeItems && discount.includeItems.length) || (discount.excludeItems && discount.excludeItems.length)" @click="showItemPopup(brand.brandCode, discount.includeItems, discount.excludeItems)">指定商品</span>
                    <span class="detail-row-text" v-else>全部商品</span>
                    <span class="detail-row-text">{{ discount.discountRate }}折</span>
                  </div>
                  <div class="detail-row" v-if="right.dayLimit || right.weekLimit || right.monthLimit">
                    <span class="detail-row-icon">·</span>
                    <span>{{ right | limitCountFilter }}</span>
                  </div>
                </div>
              </template>
            </div>
            <!-- 特殊日期折扣(目前只有折扣卡会有) -->
            <div class="card-rule-item" v-if="discountSpecialDates(right.discountDetails).length > 0">
              <div class="card-rule-item_title">
                <image class="card-rule-item_title-icon" src="/static/images/quanyi.png" mode="widthFix" />
                <span>指定日期的权益：</span>
              </div>
              <div class="card-rule-item_detail">
                <div class="detail-row" v-for="(discount, discountIndex) in discountSpecialDates(right.discountDetails)" :key="discountIndex">
                  <span class="detail-row-icon">·</span>
                  <span class="detail-row-text" v-if="discount.specialDatesDescription">{{ discount.specialDatesDescription.join('、') }}</span>
                  <span class="detail-row-text" v-else>{{ discount.specialDates.join('、') }}</span>
                  <span class="detail-row-text">{{ discountTimeDesc(discount) }}</span>
                  <span class="detail-row-text">可享</span>
                  <span class="detail-row-link" v-if="(discount.includeItems && discount.includeItems.length) || (discount.excludeItems && discount.excludeItems.length)" @click="showItemPopup(brand.brandCode, discount.includeItems, discount.excludeItems)">指定商品</span>
                  <span class="detail-row-text" v-else>全部商品</span>
                  <span class="detail-row-text">{{ discount.discountRate }}折</span>
                </div>
                <div class="detail-row">
                  <span class="detail-row-icon">·</span>
                  <span class="detail-row-text">当指定日期和会员卡基础权益不同时，以指定日期为准</span>
                </div>
              </div>
            </div>
            <!-- 不可用商品 -->
            <div class="card-rule-item" v-if="right.excludeDatesDescription || right.excludeDates">
              <div class="card-rule-item_title">
                <image class="card-rule-item_title-icon" src="/static/images/keyongshiduan.png" mode="widthFix" />
                <span>权益不可用日期：</span>
              </div>
              <div class="card-rule-item_detail">
                <template v-if="right.excludeDatesDescription">
                  <div class="detail-row" v-for="(desc, descIndex) in right.excludeDatesDescription" :key="descIndex">
                    <span class="detail-row-icon">·</span>
                    <span class="detail-row-text">{{ desc }}</span>
                  </div>
                </template>
                <div class="detail-row" v-else>
                  <span class="detail-row-icon">·</span>
                  <span class="detail-row-text">{{ right.excludeDates.join('、') }}</span>
                </div>
              </div>
            </div>
            <!-- 适用的门店 -->
            <div class="card-rule-item" v-if="brand.stores && brand.stores.length > 0">
              <div class="card-rule-item_title">
                <image class="card-rule-item_title-icon" src="/static/images/shiyongmendian.png" mode="widthFix" />
                <span>适用的门店：</span>
              </div>
              <div class="card-rule-item_detail">
                <div class="detail-row">
                  <span class="detail-row-icon">·</span>
                  <span class="detail-row-text">共适用</span>
                  <span class="detail-row-link" @click="showStorePopup(brand.brandCode, brand.stores)">{{ brand.stores.length }}家门店</span>
                </div>
              </div>
            </div>
            <!-- 适用的就餐方式 -->
            <div class="card-rule-item">
              <div class="card-rule-item_title">
                <image class="card-rule-item_title-icon" src="/static/images/jiucan.png" mode="widthFix" />
                <span>适用的就餐方式：</span>
              </div>
              <div class="card-rule-item_detail">
                <div class="detail-row">
                  <span class="detail-row-icon">·</span>
                  <span class="detail-row-text">{{ right.channel | channelFilter }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-rule">
          <div class="card-rule-item">
            <div class="card-rule-item_title">
              <image class="card-rule-item_title-icon" src="/static/images/guize.png" mode="widthFix" />
              <span>系统规则：</span>
            </div>
            <div class="card-rule-item_detail">
              <div class="detail-row">
                <span class="detail-row-icon">·</span>
                <span class="detail-row-text">{{ buyTitle }}后不可退卡，到期后自动失效</span>
              </div>
              <div class="detail-row" v-if="!cardInfo.additionalDiscountStatus">
                <span class="detail-row-icon">·</span>
                <span class="detail-row-text">不与其他优惠折上折</span>
              </div>
              <div class="detail-row">
                <span class="detail-row-icon">·</span>
                <span class="detail-row-text">仅本人支付时可使用此卡</span>
              </div>
              <div class="detail-row">
                <span class="detail-row-icon">·</span>
                <span class="detail-row-text">订单可使用的权益，根据点击【菜谱-选好了】按钮时间为准；</span>
              </div>
              <div class="detail-row">
                <span class="detail-row-icon">·</span>
                <span class="detail-row-text">各时段的优惠权益，包含时段开始时间，不包含时段结束时间；</span>
              </div>
              <div class="detail-row">
                <span class="detail-row-icon">·</span>
                <span class="detail-row-text">本公司旗下品牌发放的“所有会员卡和优惠券”，每日最多可在本司品牌下最多2家门店使用（当日已在2家店使用任意“本司的会员卡和优惠券”下单后，第3家店起无法再使用任何“本司的会员卡和优惠券”）；</span>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- 储值卡 -->
      <template v-else>
        <div class="card-rule">
          <div class="card-rule-title">权益&规则</div>
          <div class="card-rule-item">
            <div class="card-rule-item_title">
              <image class="card-rule-item_title-icon" src="/static/images/youxiaoqi.png" mode="widthFix" />
              <span>有效期：</span>
            </div>
            <!-- 福利卡的储值卡 -->
            <template v-if="isWelfareCard">
              <div class="card-rule-item_detail">
                <div class="detail-row" v-if="expiredTime">
                  <span class="detail-row-icon">·</span>
                  <span class="detail-row-text">有效期至{{ dateFormatDesc(expiredTime) }}</span>
                </div>
                <div class="detail-row">
                  <span class="detail-row-icon">·</span>
                  <span class="detail-row-text">在职期间可用</span>
                </div>
              </div>
            </template>
            <!-- 公关卡的储值卡 -->
            <template v-if="isRelationCard">
              <div class="card-rule-item_detail">
                <div class="detail-row">
                  <span class="detail-row-icon">·</span>
                  <span class="detail-row-text">有效期{{ cardInfo.validDays }}天</span>
                </div>
              </div>
            </template>
            <!-- 普通的储值卡 -->
            <template v-else>
              <div class="card-rule-item_detail">
                <div class="detail-row">
                  <span class="detail-row-icon">·</span>
                  <span class="detail-row-text">{{ effectHoursDesc }}</span>
                </div>
              </div>
            </template>
          </div>
          <div class="card-rule-item" v-if="valueCardPrensendShow">
            <div class="card-rule-item_title">
              <image class="card-rule-item_title-icon" src="/static/images/quanyi.png" mode="widthFix" />
              <span>权益：</span>
            </div>
            <!-- 储值增值卡 -->
            <div class="card-rule-item_detail">
              <div class="row-container" v-for="(price, priceIndex) in cardInfo.salePrices" :key="priceIndex">
                <div class="present-row">
                  <div class="present-row-icon">·</div>
                  <div class="present-row-right">
                    <!-- 福利卡/公关卡的储值卡 -->
                    <span class="recharge-text" v-if="isWelfareCard || isRelationCard">储值{{ price.price }}元</span>
                    <!-- 普通储值卡 -->
                    <span class="recharge-text" v-else>{{ cardPresentDesc(price) }}</span>
                  </div>
                </div>
                <!-- 赠券 -->
                <div class="coupon-text" v-if="price.coupons && price.coupons.length">
                  <div class="present-row-text" v-for="(couponItem, couponIndex) in price.coupons" :key="couponIndex">
                    <span class="color-text-click" v-if="multipleBrandCard" @click="showPromo(couponItem)">{{ couponItem.brandName }}-</span>
                    <span class="color-text-click" @click="showPromo(couponItem)">{{ couponItem.promoName }}</span>
                    <span>{{ couponItem.quantity }}张</span>
                    <span v-if="couponIndex < price.coupons.length - 1">、</span>
                  </div>
                </div>
              </div>
              <div class="detail-row" v-if="cardInfo.marketingRule.rechargeAmountEffectiveTime > 0">
                <span class="detail-row-icon">·</span>
                <span class="detail-row-text">赠送金额有效期{{ cardInfo.marketingRule.rechargeAmountEffectiveTime }}天</span>
                <template v-if="cardInfo.marketingRule.rechargeAmountExpiredMode === 1">
                  <span class="detail-row-text">，有效期届满后，将自动延期{{ cardInfo.marketingRule.rechargeAmountExpiredAddDays }}天，发卡人将于该延期期限到期日的次日从赠金余额中扣除此次延期的延期管理费；</span>
                  <span class="detail-row-text">延期管理费按有效期到期日该卡赠金余额的{{ cardInfo.marketingRule.rechargeAmountExpiredChargeRate }}%收取，但最低为人民币{{ cardInfo.marketingRule.rechargeAmountExpiredChargeLimit }}元。</span>
                  <span class="detail-row-text">若此延期的到期日卡内仍有赠金余额，将再自动延期{{ cardInfo.marketingRule.rechargeAmountExpiredAddDays }}天，发卡人将于第二次延期期限到期日的次日从赠金余额中扣除第二次延期的延期管理费；</span>
                  <span class="detail-row-text">延期管理费按有效期到期日该卡赠金余额的{{ cardInfo.marketingRule.rechargeAmountExpiredChargeRate }}%收取，但最低为人民币{{ cardInfo.marketingRule.rechargeAmountExpiredChargeLimit }}元。</span>
                  <span class="detail-row-text">之后延期期限、延期方式和延期管理费的计算与收取以此类推。延期管理费将直接从该卡的赠金余额中扣取，直到扣完为止。</span>
                </template>
              </div>
            </div>
          </div>
          <!-- 可用时段 -->
          <div class="card-rule-item">
            <div class="card-rule-item_title">
              <image class="card-rule-item_title-icon" src="/static/images/keyongshiduan.png" mode="widthFix" />
              <span>可用时段：</span>
            </div>
            <div class="card-rule-item_detail">
              <div class="detail-row" v-for="(time, timeIndex) in valueCardDiscountDetail.discountDetails[0].discountTimes" :key="timeIndex">
                <span class="detail-row-icon">·</span>
                <span class="detail-row-text">{{ weekdayDesc(time.weekDay) }} {{ timeRangeDesc(time) }}</span>
              </div>
            </div>
          </div>
          <!-- 不可用日期 -->
          <div class="card-rule-item" v-if="valueCardDiscountDetail.excludeDatesDescription || valueCardDiscountDetail.excludeDates">
            <div class="card-rule-item_title">
              <image class="card-rule-item_title-icon" src="/static/images/keyongshiduan.png" mode="widthFix" />
              <span>不可用日期：</span>
            </div>
            <div class="card-rule-item_detail">
              <template v-if="valueCardDiscountDetail.excludeDatesDescription">
                <div class="detail-row" v-for="(desc, descIndex) in valueCardDiscountDetail.excludeDatesDescription" :key="descIndex">
                  <span class="detail-row-icon">·</span>
                  <span class="detail-row-text">{{ desc }}</span>
                </div>
              </template>
              <div class="detail-row" v-else>
                <span class="detail-row-icon">·</span>
                <span class="detail-row-text">{{ valueCardDiscountDetail.excludeDates.join('、') }}</span>
              </div>
            </div>
          </div>
          <!-- 不可用商品 -->
          <div class="card-rule-item" v-if="cardInfo.brands && cardInfo.brands.some((x) => x.discounts.some((y) => y.discountDetails.some((z) => z.excludeItems && z.excludeItems.length > 0)))">
            <div class="card-rule-item_title">
              <image class="card-rule-item_title-icon" src="/static/images/jiucan.png" mode="widthFix" />
              <span>不可支付的商品：</span>
            </div>
            <div class="card-rule-item_detail">
              <template v-for="(brand, brandIndex) in cardInfo.brands">
                <div class="detail-row" v-if="brand.discounts[0].discountDetails[0].excludeItems && brand.discounts[0].discountDetails[0].excludeItems.length > 0" :key="brandIndex">
                  <span class="detail-row-icon">·</span>
                  <span class="detail-row-text">【{{ brand.brandName }}】</span>
                  <span class="detail-row-link" @click="showItemPopup(brand.brandCode, brand.discounts[0].discountDetails[0].excludeItems, null)">{{ brand.discounts[0].discountDetails[0].excludeItems.length }}款指定商品</span>
                </div>
              </template>
            </div>
          </div>
          <!-- 适用的就餐方式 -->
          <div class="card-rule-item">
            <div class="card-rule-item_title">
              <image class="card-rule-item_title-icon" src="/static/images/jiucan.png" mode="widthFix" />
              <span>适用的就餐方式：</span>
            </div>
            <div class="card-rule-item_detail">
              <div class="detail-row">
                <span class="detail-row-icon">·</span>
                <span class="detail-row-text">{{ valueCardDiscountDetail.channel | channelFilter }}</span>
              </div>
            </div>
          </div>
          <div class="card-rule-item">
            <div class="card-rule-item_title">
              <image class="card-rule-item_title-icon" src="/static/images/guize.png" mode="widthFix" />
              <span>系统规则：</span>
            </div>
            <div class="card-rule-item_detail">
              <!-- 福利卡和公关卡不展示退卡, 转赠的卡不允许退卡 -->
              <template v-if="cardSource !== 2 && !isWelfareCard && !isRelationCard">
                <!-- 储值增值卡 -->
                <div class="detail-row">
                  <span class="detail-row-icon">·</span>
                  <span class="detail-row-text" v-if="cardInfo.marketingRule.refundDaysLimit">储值卡可在购买的{{ cardInfo.marketingRule.refundDaysLimit }}天后申请退卡。</span>
                  <span class="detail-row-text" v-else-if="cardInfo.marketingRule.refundChargeRate">储值卡退卡，</span>
                  <span class="detail-row-text" v-if="cardInfo.marketingRule.refundChargeRate">按退卡本金余额的{{ cardInfo.marketingRule.refundChargeRate }}%收取退卡手续费，最低人民币{{ cardInfo.marketingRule.refundChargeLimit }}元。</span>
                  <span class="detail-row-text">储值卡退卡金额，将从购卡实付金额中扣除已使用金额</span>
                  <span class="detail-row-text" v-if="cardInfo.salePrices.some((x) => x.coupons && x.coupons.length)">及赠券的已优惠金额（已优惠金额包含已使用及已赠出的券）</span>
                  <span class="detail-row-text" v-if="cardInfo.marketingRule.refundChargeRate">，再扣除退卡手续费后</span>
                  <span class="detail-row-text">，剩余金额退回至原账户。可在《悠订》公众号的“我的”查看可用余额和申请退卡。</span>
                  <span class="detail-row-text">连续365天内累计退卡超过3次的购卡人，将在之后的365天内无法再次领取和购买本公司发行的任何预付卡和预付券；</span>
                </div>
              </template>

              <!-- 福利卡和公关卡的储值卡不展示 -->
              <div class="detail-row" v-if="!isWelfareCard && !isRelationCard">
                <span class="detail-row-icon">·</span>
                <span class="detail-row-text">储值卡金额付款规则：优先扣除[储值本金]；</span>
              </div>

              <div class="detail-row">
                <span class="detail-row-icon">·</span>
                <span class="detail-row-text" v-if="cardInfo.marketingRule.presentStatus === 1">此卡未使用时，可转赠他人，受赠人不可申请退卡；赠卡时，赠送的优惠券不可一起转赠；如单独转赠优惠券，则未赠送的卡不可申请退卡。</span>
                <span class="detail-row-text" v-else>此卡不可转赠；</span>
              </div>
              <div class="detail-row" v-if="!cardInfo.additionalDiscountStatus">
                <span class="detail-row-icon">·</span>
                <span class="detail-row-text">不与其他优惠折上折；</span>
              </div>
              <div class="detail-row">
                <span class="detail-row-icon">·</span>
                <span class="detail-row-text">仅本人支付时可使用此卡；</span>
              </div>
              <div class="detail-row" v-if="cardInfo.marketingRule.buyLimit > 0">
                <span class="detail-row-icon">·</span>
                <span class="detail-row-text">此卡每人最多可购买{{ cardInfo.marketingRule.buyLimit }}张；</span>
              </div>
              <div class="detail-row">
                <span class="detail-row-icon">·</span>
                <span class="detail-row-text">本公司旗下品牌发放的“所有会员卡和优惠券”，每日最多可在本司品牌下最多2家门店使用（当日已在2家店使用任意“本司的会员卡和优惠券”下单后，第3家店起无法再使用任何“本司的会员卡和优惠券”）；</span>
              </div>
              <div class="detail-row" v-if="!isWelfareCard && !isRelationCard">
                <span class="detail-row-icon">·</span>
                <span class="detail-row-text">根据国家税务总局2016年第53号《关于营改增试点若干征管问题的公告》，向购卡人、充值人开具支付金额相等的“预付卡销售”增值税普通发票。使用储值卡消费后，商户将不再就消费订单中储值卡支付的金额部分开具发票。</span>
              </div>
            </div>
          </div>
          <!-- 适用的门店 -->
          <div class="card-rule-item">
            <div class="card-rule-item_title">
              <image class="card-rule-item_title-icon" src="/static/images/shiyongmendian.png" mode="widthFix" />
              <span>适用的门店：</span>
            </div>
            <div class="card-rule-item_detail">
              <div class="detail-row" v-for="(brand, brandIndex) in cardInfo.brands" :key="brandIndex">
                <span class="detail-row-icon">·</span>
                <span class="detail-row-text">【{{ brand.brandName }}】</span>
                <span class="detail-row-link" @click="showStorePopup(brand.brandCode, brand.stores)">{{ brand.stores.length }}家门店</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <!-- loading提示 -->
    <loading-box :show="loadingShow" />
    <!-- 门店弹窗组件 -->
    <store-select-popup :show.sync="storePopupShow" :brandCode="selectedBrandCode" :selectedIds="selectedStoreIds" :editable="false" title="此“优惠”适用的门店" />
    <!-- 商品弹窗组件 -->
    <item-select-popup :show.sync="itemPopupShow" :brandCode="selectedBrandCode" :selectedIds="includeItemIds" :excludeIds="excludeItemIds" :editable="false" />
    <!-- 券详情 -->
    <card-promo-detail-popup :promoId="detailPromoId" :show.sync="storePromoDetailPopupShow" :way="3" :userId="userId" />
  </div>
</template>
<script>
import LoadingBox from '@/components/common/LoadingBox'
import CardPromoDetailPopup from '@/components/business/card/CardPromoDetailPopup'
import StoreSelectPopup from '@/components/business/StoreSelectPopup'
import ItemSelectPopup from '@/components/business/ItemSelectPopup'
import { getCardDetailApi } from '@/api/card'
export default {
  name: 'card-detail-content',
  components: { LoadingBox, CardPromoDetailPopup, ItemSelectPopup, StoreSelectPopup },
  props: {
    cardId: { type: String, required: true, default: '', desc: '卡Id' },
    way: { type: Number, required: true, default: 3, desc: '购买渠道' },
    wayId: { type: String, required: false, default: '', desc: '渠道Id' },
    userId: { type: String, required: false, default: 'userId', desc: 'userId' },
    storeId: { type: String, required: false, default: '', desc: '门店Id' },
    withHeader: { type: Boolean, required: false, default: false, desc: '是否显示卡顶部区域信息（图片+描述）' },
    withLoading: { type: Boolean, require: false, default: false, desc: '是否展示loading' },
    expiredTime: { type: String, require: false, default: '', desc: '会员卡到期时间（个人中心用)' },
    cardSource: { type: Number, require: false, default: 0, desc: '会员卡来源： 1-购卡，2-转赠（个人中心用)' },
    defaultFaceImage: { type: String, required: false, default: '', desc: '默认封面图，用于卡券商城' }
  },
  data() {
    return {
      loadingShow: false,
      cardInfo: {},
      storePopupShow: false,
      selectedBrandCode: '',
      selectedStoreIds: [],
      itemPopupShow: false,
      includeItemIds: [],
      excludeItemIds: [],
      storePromoDetailPopupShow: false,
      detailPromoId: ''
    }
  },
  filters: {
    brandNameFilter(val) {
      if (!val) {
        return ''
      }
      return `【${val.map((x) => x.brandName).join('、')}】`
    },

    priceDescFilter(val) {
      if (!val) {
        return '（免费领取）'
      }
      return `（${val}元）`
    },
    limitCountFilter(val) {
      const result = []
      if (val.dayLimit) {
        result.push(`每天最多可使用权益${val.dayLimit}次`)
      }
      if (val.weekLimit) {
        result.push(`每周最多可使用权益${val.weekLimit}次`)
      }
      if (val.monthLimit) {
        result.push(`每月最多可使用权益${val.monthLimit}次`)
      }
      return result.join('、')
    },
    channelFilter(val) {
      if (!val) {
        return ''
      }
      const result = []
      if (val.indexOf('1') > -1) {
        result.push('堂食')
      }
      if (val.indexOf('2') > -1) {
        result.push('外带')
      }
      if (val.indexOf('4') > -1) {
        result.push('外卖')
      }
      if (result.length === 0) {
        return ''
      } else if (result.length === 1) {
        return '仅' + result[0]
      } else {
        return result.join('、') + '均可使用'
      }
    },
    couponAmountText(val) {
      if (val.totalAmount) {
        return `及价值${val.totalAmount}元的优惠券`
      }
      return ''
    }
  },
  computed: {
    nextTimeDesc() {
      return (val) => {
        if (!val) {
          return ''
        }
        return `将于${this.$formatDate(val, 'MM月DD日 HH:mm')}开售`
      }
    },
    // isValueCard指的是储值类的卡，这类卡全品牌通用规则，也就是说所有品牌下的优惠规则discountInfo是一样的，而其他例如折扣类的卡，每个品牌都有自己的一套规则
    isValueCard() {
      return this.cardInfo?.cardType === '6'
    },
    // 是否福利卡
    isWelfareCard() {
      return this.cardInfo?.memberType === 6
    },
    // 是否公关卡
    isRelationCard() {
      return this.cardInfo?.memberType === 7
    },
    // 储值类的卡，折扣信息直接取第一条数据
    valueCardDiscountDetail() {
      return this.cardInfo.brands?.[0].discounts?.[0] || {}
    },
    cardDiscountDesc() {
      const card = this.cardInfo
      if (!card.cardType) {
        return ''
      }
      if (this.isValueCard) {
        if (!this.valueCardPrensendShow) {
          return ''
        }
        // 储值增值卡
        let rechargeDescList = []
        card.salePrices.forEach((x) => {
          let desc = `储值${x.price}元`
          if (x.rechargeAmount) {
            desc += `赠送${x.rechargeAmount}元`
          }
          if (x.coupons && x.coupons.length) {
            if (x.rechargeAmount) {
              desc += '及'
            }
            desc += '优惠券'
          }
          rechargeDescList.push(desc)
        })
        if (rechargeDescList.length === 0) {
          return ''
        }
        let rechargeDesc = rechargeDescList[0]
        if (rechargeDescList.length > 1) {
          rechargeDesc += '，储值更多获赠更多'
        }
        return rechargeDesc
      }
      // 折扣卡
      const discountRates = card.brands
        .reduce((r1, x1) => {
          return r1.concat(
            x1.discounts.reduce((r2, x2) => {
              return r2.concat(x2.discountDetails)
            }, [])
          )
        }, [])
        .map((x) => x.discountRate)
      if (!discountRates || discountRates.length === 0) {
        return ''
      }
      let discount = ''
      if (discountRates.length === 1) {
        discount = discountRates[0]
      } else {
        const min = Math.min.apply(null, discountRates)
        const max = Math.max.apply(null, discountRates)
        if (min === max) {
          discount = min
        } else {
          discount = `${min}-${max}`
        }
      }
      return `凭卡可在指定时段享受指定商品${discount}折优惠`
    },
    valueCardPrensendShow() {
      if (!this.cardInfo.salePrices) {
        return false
      }
      return this.cardInfo.salePrices.some((x) => x.rechargeAmount || x.totalAmount)
    },
    cardPresentDesc() {
      return (sale) => {
        const presentList = []
        if (sale.rechargeAmount) {
          presentList.push(`${sale.rechargeAmount}元`)
        }
        if (sale.totalAmount) {
          presentList.push(`价值${sale.totalAmount}元的优惠券`)
        }
        if (!presentList.length) {
          return `储值${sale.maxPrice ? '满' : ''}${sale.price}元无赠券`
        }
        return `储值${sale.maxPrice ? '满' : ''}${sale.price}元赠送${presentList.join('及')}`
      }
    },
    discountTimes() {
      return (val) => {
        return val.filter((x) => !x.specialDates || x.specialDates.length === 0)
      }
    },
    discountSpecialDates() {
      return (val) => {
        return val.filter((x) => x.specialDates && x.specialDates.length > 0)
      }
    },
    discountTimeDesc() {
      return (val) => {
        if (!val || !val.discountTimes) {
          return ''
        }
        const dateStrArray = val.discountTimes.map((x) => `${this.weekdayDesc(x.weekDay)} ${this.timeRangeDesc(x)}`)
        return `${dateStrArray.join('、')}`
      }
    },
    weekdayDesc() {
      return (val) => {
        if (!val) {
          return ''
        }
        if (val.indexOf('8') > -1) {
          return '全部法定工作日（包含有效期内因法定节假日调休上班的周六、日；不包含有效期内因法定节假日调休的工作日）'
        }
        const weekdayList = val.split(',').map((x) => parseInt(x))
        weekdayList.sort((a, b) => a - b)
        const len = weekdayList.length
        const weekdayDescList = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
        const firstvalue = weekdayList[0]
        const lastValue = weekdayList[len - 1]
        // 如果只有一天，则直接输出
        if (len === 1) {
          return weekdayDescList[firstvalue - 1]
        }
        // 如果首尾天数差等于总天数，说明是连续的
        if (lastValue - firstvalue === len - 1) {
          return weekdayDescList[firstvalue - 1] + '至' + weekdayDescList[lastValue - 1]
        }
        // 不连续的则直接全部输出
        return weekdayList.map((x) => weekdayDescList[x - 1]).join('、')
      }
    },
    timeRangeDesc() {
      return (val) => {
        if (!val.fromTime || !val.toTime) {
          return ''
        }
        return val.fromTime + '-' + val.toTime
      }
    },
    buyTitle() {
      const priceList = this.cardInfo.salePrices?.map((x) => x.price)
      const minPirce = Math.min.apply(null, priceList)
      return minPirce ? '购买' : '领取'
    },
    effectHoursDesc() {
      if (!this.cardInfo.saleWay) {
        return `${this.buyTitle}后立即生效`
      }
      if (!this.cardInfo.saleWay.effectType) {
        // 如果本金是立即生效，但赠金是次日生效，那么话术分开
        if (this.cardInfo.saleWay.rechargeEffectType === 1) {
          return `${this.buyTitle}后“储值本金”立即生效，“赠送金额”次日生效`
        }
        // 如果本金是立即生效，并且赠金也是立即生效，那么话术合并
        return `${this.buyTitle}后立即生效`
      }
      // 如果是本金次日生效，那么赠金肯定也是跟本金一起生效
      if (this.cardInfo.saleWay.effectType === 1) {
        return `${this.buyTitle}后次日生效`
      }
      // 如果本金是几小时后生效，那么赠金也是跟本金一起生效，所以话术合并
      if (this.cardInfo.saleWay.effectType === 2 && this.cardInfo.saleWay.effectHours) {
        return `${this.buyTitle}后${this.cardInfo.saleWay.effectHours}小时生效`
      }
      return `${this.buyTitle}后立即生效`
    },
    presentCouponDesc() {
      return (coupon) => {
        let desc = `充值${coupon.limitAmount}元`
        if (coupon.coupons?.length) {
          desc += `赠价值${coupon.totalAmount}元优惠券`
        } else {
          desc += '无赠券'
        }
        return desc
      }
    },
    // 是否是联名卡
    multipleBrandCard() {
      if (this.cardInfo.brands && this.cardInfo.brands.length > 1) {
        return true
      } else {
        return false
      }
    },
    memberPriceItemIds() {
      return (val) => {
        if (!val?.length) {
          return []
        }
        return val.map((x) => x.itemId)
      }
    },
    dateFormatDesc() {
      return (val) => {
        if (!val) {
          return ''
        }
        return this.$formatDate(val, 'yyyy-MM-DD')
      }
    }
  },
  watch: {
    cardId: {
      handler(val) {
        if (val) {
          if (this.withLoading) {
            this.loadingShow = true
          }
          this.getCardDetail()
        }
      },
      immediate: true
    }
  },
  methods: {
    getCardDetail() {
      getCardDetailApi(this.cardId, this.userId, this.way, this.wayId, this.storeId).then((res) => {
        if (res.status !== '1' || !res.data) {
          this.$toast(`会员卡详情获取失败`)
          return false
        }
        this.loadingShow = false

        this.cardInfo = res.data
        // 赠券数据过滤
        if (this.cardInfo.salePrices && this.cardInfo.salePrices.length) {
          this.cardInfo.salePrices.forEach((x) => {
            if (x.coupons) {
              x.coupons = x.coupons.filter((y) => y.quantity > 0)
            }
          })
        }
        // 自定义充值金额卡，赠券规则按充值金额排序
        if (this.cardInfo.presentCoupons?.length) {
          this.cardInfo.presentCoupons.sort((a, b) => a.limitAmount - b.limitAmount)
          this.cardInfo.presentCoupons.forEach((x) => {
            x.coupons = x.coupons.filter((y) => y.quantity > 0)
          })
        }
        this.$emit('getCardInfo', this.cardInfo)
      })
    },
    transferImage(key) {
      return this.$transferQnyUrl(key, 800)
    },
    showStorePopup(brandCode, stores) {
      if (!brandCode || !stores || stores.length === 0) {
        return false
      }
      this.selectedBrandCode = brandCode
      this.selectedStoreIds = stores.map((x) => x.storeId)
      this.storePopupShow = true
    },
    showItemPopup(brandCode, includeItems, excludeItems) {
      if (!brandCode) {
        return false
      }
      if (!includeItems && !excludeItems) {
        return false
      }
      this.selectedBrandCode = brandCode
      this.includeItemIds = []
      this.excludeItemIds = []
      if (includeItems && includeItems.length > 0) {
        this.includeItemIds = includeItems.map((x) => x.itemId)
      }
      if (excludeItems && excludeItems.length > 0) {
        this.excludeItemIds = excludeItems.map((x) => x.itemId)
      }
      this.itemPopupShow = true
    },
    showPromo(couponItem) {
      this.detailPromoId = couponItem.promoId
      this.storePromoDetailPopupShow = true
    },
    closePopup() {
      this.itemPopupShow = false
      this.storePopupShow = false
      this.storePromoDetailPopupShow = false
    }
  }
}
</script>
<style lang="scss" scoped>
.card-image {
  width: 100%;
  padding-top: 24px;
  background-color: $color-white;
  .card-image-content {
    position: relative;
    width: 600px;
    height: 300px;
    margin: 0 auto;
    // margin-bottom: 8px;
    font-size: 0;
    /deep/ .van-image img {
      width: 600px;
      height: 300px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
      border-radius: 16px;
    }
    .card-tag {
      position: absolute;
      bottom: 20px;
      left: 24px;
      .card-tag_item {
        display: inline-block;
        min-width: 110px;
        height: 40px;
        padding: 0 8px;
        line-height: 40px;
        text-align: center;
        font-size: 24px;
        color: $color-white;
        background-color: $color-danger-main;
        border-radius: 20px;
        &:not(:first-child) {
          margin-left: 12px;
        }
      }
    }
  }
}
.card-header {
  width: 100%;
  padding: 24px;
  background-color: $color-white;

  .card-header-title {
    line-height: 44px;
    color: $color-text-main;
    font-size: 32px;
    font-weight: bold;
  }
  .card-header-title_tag {
    display: inline-block;
    padding: 4px 6px;
    margin-left: 16px;
    line-height: 28px;
    font-size: 22px;
    font-weight: normal;
    color: $color-white;
    background-color: $color-warning;
    border-radius: 4px;
  }
  .card-header-desc {
    font-size: 24px;
    margin-top: 16px;
  }
  .card-header-price {
    margin-top: 26px;
    line-height: 36px;
    color: $color-danger;
    font-size: 24px;
    .card-header-price_num {
      color: $color-danger;
      font-size: 30px;
      font-weight: bold;
    }
    .card-header-price_discount {
      display: inline-block;
      padding: 4px 16px;
      margin-left: 16px;
      line-height: 24px;
      color: $color-danger;
      font-size: 20px;
      font-weight: bold;
      border: 1px solid $color-danger;
      border-radius: 8px;
    }
  }
}
.card-content {
  width: 100%;
  .card-rule {
    width: 100%;
    padding: 24px;
    background-color: $color-white;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    .card-rule-title {
      width: 100%;
      margin-bottom: 24px;
      line-height: 44px;
      color: $color-text-main;
      font-size: 32px;
      font-weight: bold;
    }
    .card-rule-item {
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
      .card-rule-item_title {
        width: 100%;
        line-height: 40px;
        color: $color-text-main;
        font-size: 28px;
        font-weight: bold;

        .card-rule-item_title-icon {
          width: 40px;
          height: 40px;
          margin-right: 8px;
          vertical-align: bottom;
        }
      }
      .card-rule-item_detail {
        .detail-row {
          width: 100%;
          margin-top: 8px;
          line-height: 40px;
          font-size: 28px;
          color: $color-text-main;
          .detail-row-icon {
            margin-right: 8px;
            font-size: 30px;
            font-weight: bold;
          }
          .detail-row-text {
            font-size: 28px;
            color: $color-text-main;
          }
          .detail-row-price {
            font-size: 28px;
            color: $color-danger;
          }
          .detail-row-link {
            font-size: 28px;
            color: $color-text-click;
          }
        }
        .present-row {
          display: flex;
          width: 100%;
          margin-top: 8px;
          line-height: 40px;
          font-size: 28px;
          color: $color-text-main;
          .present-row-icon {
            margin-right: 8px;
            font-size: 30px;
            font-weight: bold;
          }
          .present-row-right {
            display: flex;
            // align-items: center;
            flex-wrap: wrap;
            .recharge-text {
              font-size: 28px;
              color: $color-text-main;
            }
          }
        }
        .coupon-text {
          .coupon-text-arrow {
            font-size: 28px;
            color: $color-text-main;
          }
          .present-row-text {
            display: flex;
            align-items: center;
            font-size: 28px;
            color: $color-text-main;
            padding-left: 16px;
          }
        }
      }
    }
  }
}
</style>
